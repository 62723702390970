.file-name[data-v-b4a20316] {
  color: #6F91C1;
  cursor: pointer;
  text-decoration: underline;
}
.headInfo[data-v-b4a20316] {
  padding: 15px 12px 30px 12px;
  font-size: 14px;
  color: #3f4448;
}
.headInfo label[data-v-b4a20316] {
  width: 100px;
}
.file-table[data-v-b4a20316] {
  width: 100%;
  text-align: center;
  border-color: #cccccc;
  font-size: 14px;
  color: #606266;
}
[data-v-b4a20316] .el-input__inner {
  margin-right: 0 !important;
}